import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/sun/sunproducts/tinted-mineral-product-front-cap-zoom.webp";
import backImage from "images/sun/sunproducts/tinted-mineral-product-front.webp";
import thirdImage from "images/sun/sunproducts/tinted-mineral-product-front-box.webp"; //new image path here

import frontZoomImage from "images/sun/sunproducts/tinted-mineral-product-front-cap-zoom.webp";
import backZoomImage from "images/sun/sunproducts/tinted-mineral-product-front-zoom.webp";
import thirdImageZoom from "images/sun/sunproducts/tinted-mineral-product-front-box-zoom.webp"; //new image path here

import img4 from "images/sun/sunproducts/tinted-mineral-blendability.webp";
import img5 from "images/sun/sunproducts/tinted-mineral-pigmentation.webp";
import img6 from "images/sun/sunproducts/tinted-mineral-erythema.webp";


import img9 from "images/callouts/cout-footer-dry-skin-therapy.webp";
import img10 from "images/callouts/cout-footer-5aox.webp";
import img11 from "images/callouts/cout-footer-journal.webp";

import "./tintedmineral.scss";

function TintedMineralPage({ pageContext }) {
  useGoToAnchor();

  return (
    <Layout
      pageClass="page-sun-eucerinsunproducts-tinted-mineral page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Sun Tinted Sensitive Mineral Face"
        pageDescription="Learn about Eucerin Sun Tinted Sensitive Mineral Face for all skin types and tones"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#E07810"
              categoryName="SUN CARE"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Sun Tinted Sensitive Mineral Face"
              productSlider={[frontImage, backImage, thirdImage]}
              zoomImages={[frontZoomImage, backZoomImage, thirdImageZoom]}
            >
              <div className="functions">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Universal tint blends with all skin tones
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Sun Tinted Sensitive Mineral Face
                  </span>{" "}
                  is a 100% mineral sunscreen with a sheer, iron oxide tint and
                  5 antioxidants that go beyond broad-spectrum protection. It’s
                  gentle enough for sensitive skin and suitable for use under
                  makeup, leaving an invisible finish.
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>
                      <span>24% zinc oxide (sunscreen active ingredient)</span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Formulation helps defend against free radicals and helps
                      combat the signs of aging*
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Active ingredient
                </p>
                <p className="no-mb font-semi-bold">24% zinc oxide</p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>
                      Provides broad-spectrum sun protection for sensitive skin
                    </span>
                  </li>
                  <li>
                    <span>
                      Formulated to be gentle and <nobr>non-irritating</nobr>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb font-semi-bold">Iron oxides</p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>
                      Sheer, universal tint formulation blends seamlessly with
                      all skin tones
                    </span>
                  </li>
                </ul>
                <p className="no-mb font-semi-bold">5 AOX Shield™ helps to:</p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>Neutralize free radicals</span>
                  </li>
                  <li>
                    <span>Guard against free radical formation</span>
                  </li>
                  <li>
                    <span>Defend against natural antioxidant breakdown</span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold mango-tango-text sub-title">
                    Formulated for sensitive skin
                  </p>
                  <ul className="checkmarks mango-tango">
                    <li>
                      <span>Hypoallergenic</span>
                    </li>
                    <li>
                      <span>non-comedogenic</span>
                    </li>
                    <li>
                      <span>ophthalmologist-tested</span>
                    </li>
                  </ul>
                  <ul className="checkmarks mango-tango">
                    <li>
                      <span>Fragrance-free</span>
                    </li>
                    <li>
                      <span>octinoxate-free</span>
                    </li>
                    <li>
                      <span>oxybenzone-free</span>
                    </li>
                  </ul>
                </div>
                <div className="footnotes">
                  <table cellpadding="0" cellspacing="0">
                    <tr>
                      <td width="4" valign="top" className="footnotes">
                        *
                      </td>
                      <td valign="top" className="footnotes">
                        Use and reapply as directed with other sun protection
                        measures (see Drug Facts Box).
                      </td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="Pigmentation"
              productThemeColor="sun-mango-tango"
              id="pigmentation"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img5}
                    className="accordion-chart"
                    alt="Real-world patient feedback on product benefits"
                  />
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Provided significant improvement compared to control and
                    indicated products
                  </div>
                </Col>
              </Row>
              <Row align="start" justify="center" direction="row">
                <Col sm={12} md={5}>
                  <p className="mobilePaddingTop20">
                    <strong className="font-bold"> Product Key:</strong> <br />
                    <strong className="font-bold">Control:</strong> 24% zinc
                    oxide SPF 50 <br />
                    <strong className="font-bold">
                      {" "}
                      A: EltaMD<sup>®</sup> UV Restore Tinted Broad-Spectrum SPF
                      40
                      <br />
                      B: CeraVe<sup>®</sup> Mineral Sunscreen SPF 30 Face Sheer
                      Tint <br />
                      C: EltaMD<sup>®</sup> UV Clear Tinted Broad-Spectrum SPF
                      46 <br />
                      D: Neutrogena<sup>®</sup> Sheer Zinc™ Dry-Touch Sunscreen
                      Broad-Spectrum SPF 50
                      <br />
                      <span className="mango-tango-text">
                        Eucerin<sup>®</sup> Sun Tinted Sensitive Mineral Face
                      </span>
                    </strong>
                  </p>
                  <p className="references last" style={{ marginTop: 25 }}>
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={7}>
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> 10 female
                    subjects (FST IV-VI) were enrolled and the effectiveness of
                    Zn/Fe<sub>2</sub>O<sub>3</sub>/5 AOX sunscreen, compared to
                    several commercially available tinted and non-tinted mineral
                    sunscreens, was evaluated. The erythema and pigmentation
                    assessment was performed by diffused reflectance
                    spectroscopy (DRS), polarized photography, and investigator
                    global scoring. Evaluations were made immediately, 24 hours,
                    and 7 days after irradiation (320 J/cm<sup>2</sup>). Sample
                    data from all time points were pooled and analyzed using the
                    Fisher Least Significant Difference (LSD) Method with 95%
                    confidence.
                  </p>

                  <div className="footnotes">
                    <table cellpadding="0" cellspacing="0">
                      <tr>
                        <td width="4" valign="top" className="footnotes">
                          *
                        </td>
                        <td valign="top" className="footnotes">
                          Represents statistically significant difference (
                          <span className="font-italic">p</span>≤0.05) compared
                          to indicated products.
                        </td>
                      </tr>
                    </table>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Erythema"
              productThemeColor="sun-mango-tango"
              id="erythema"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img6}
                    className="accordion-chart"
                    alt="Real-world patient feedback on product benefits"
                  />
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Provided significant improvement compared to control and
                    indicated product
                  </div>
                </Col>
              </Row>
              <Row align="start" justify="center" direction="row">
                <Col sm={12} md={5}>
                  <p className="mobilePaddingTop20">
                    <strong className="font-bold"> Product Key:</strong> <br />
                    <strong className="font-bold">Control:</strong> 24% zinc
                    oxide SPF 50 <br />
                    <strong className="font-bold">
                      {" "}
                      A: EltaMD<sup>®</sup> UV Restore Tinted Broad-Spectrum SPF
                      40
                      <br />
                      B: CeraVe<sup>®</sup> Mineral Sunscreen SPF 30 Face Sheer
                      Tint <br />
                      C: EltaMD<sup>®</sup> UV Clear Tinted Broad-Spectrum SPF
                      46 <br />
                      D: Neutrogena<sup>®</sup> Sheer Zinc™ Dry-Touch Sunscreen
                      Broad-Spectrum SPF 50
                      <br />
                      <span className="mango-tango-text">
                        Eucerin<sup>®</sup> Sun Tinted Sensitive Mineral Face
                      </span>
                    </strong>
                  </p>
                  <p className="references last" style={{ marginTop: 25 }}>
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={7}>
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> 10 female
                    subjects (FST IV-VI) were enrolled and the effectiveness of
                    Zn/Fe<sub>2</sub>O<sub>3</sub>/5 AOX sunscreen, compared to
                    several commercially available tinted and non-tinted mineral
                    sunscreens, was evaluated. The erythema and pigmentation
                    assessment was performed by diffused reflectance
                    spectroscopy (DRS), polarized photography, and investigator
                    global scoring. Evaluations were made immediately, 24 hours,
                    and 7 days after irradiation (320 J/cm<sup>2</sup>). Sample
                    data from all time points were pooled and analyzed using the
                    Fisher Least Significant Difference (LSD) Method with 95%
                    confidence.
                  </p>
                  <div className="footnotes">
                    <table cellpadding="0" cellspacing="0">
                      <tr>
                        <td width="4" valign="top" className="footnotes">
                          *
                        </td>
                        <td valign="top" className="footnotes">
                          Represents statistically significant difference (
                          <span className="font-italic">p</span>≤0.05) compared
                          to indicated products.
                        </td>
                      </tr>
                    </table>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Blendability"
              productThemeColor="sun-mango-tango"
              id="benefitssurvey"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img4}
                    className="accordion-chart"
                    alt="Real-world patient feedback on product benefits"
                  />
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Leaves skin feeling soft and smooth, with an invisible matte
                    finish
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img9}
                  imgAlt="Hydration"
                  caption="Recommend daily hydration <br class='show-desktop' /> along with sun protection"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img10}
                  imgAlt="5AOX Shield"
                  caption="Formulas to help defend against damage from oxidative stress"
                  buttonText="See the modes of defense"
                  buttonUrl="/sun/freeradicaldefense"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img11}
                  imgAlt="JAAD"
                  caption="Discover how perspectives in sun protection are evolving"
                  buttonText="VIEW JOURNAL ARTICLES"
                  buttonUrl="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                  imgStyles={{ height: 165 }}
                  isExternalLink={true}
                  targetOpen="_blank"
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default TintedMineralPage;
