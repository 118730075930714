import React from "react";
// import PropTypes from "prop-types";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';


const AccordionContent = ({accordionHeading, productThemeColor, id, children}) => {
return(
  <div className={productThemeColor}>
    <Accordion allowZeroExpanded>
      <AccordionItem id={id}>
        <AccordionItemHeading>
          <AccordionItemButton
            dangerouslySetInnerHTML={{ __html: accordionHeading }} />
        </AccordionItemHeading>
        <AccordionItemPanel>
          {children}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  </div>
)
};
export default AccordionContent;